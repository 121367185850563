import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.$vuetify.breakpoint.smAndUp)?_c(VAppBar,{attrs:{"fixed":"","flat":"","color":"#03002d","height":"80"},model:{value:(_vm.showMenuBar),callback:function ($$v) {_vm.showMenuBar=$$v},expression:"showMenuBar"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c(VImg,{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/img/logo-horizontal.svg"),"height":"58","contain":"","position":"left center"},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}})],1),_c(VCol,{staticClass:"text-right"},[_c('div',{staticClass:"header-subtitle white--text"},[_c('p',{staticClass:"my-0"},[_c('strong',[_vm._v("SCHEDULE a free INSPECTION")]),_vm._v(" "),_c('br'),_vm._v(" call us at (310) 822 1805 "),_c('strong',{staticClass:"d-block"},[_vm._v("SAME DAY SERVICE AVAILABLE")])])])])],1)],1)],1):_c(VAppBar,{staticClass:"px-0",attrs:{"fixed":"","flat":"","color":"#03002d","height":"80"},model:{value:(_vm.showMenuBar),callback:function ($$v) {_vm.showMenuBar=$$v},expression:"showMenuBar"}},[_c(VContainer,{staticClass:"px-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c(VRow,{staticStyle:{"height":"100%"},attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VImg,{attrs:{"src":require("@/assets/img/logo-horizontal.svg"),"max-width":"180"}})],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"rounded":"","small":""}},[_vm._v(" Call us")]),_c('br'),_c('span',{staticClass:"same-day-mobile-app-bar"},[_vm._v("SAME DAY SERVICE AVAILABLE ")])],1),_c(VCol,{staticClass:"text-right"})],1)],1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }